<template src="./AccountKeyword.html" />
<script lang="ts">
import { powerwordState } from '@/stores/state/powerword/powerword'
import {
  Keyword,
  Account,
} from '@/stores/model/domain'
import Multiselect from '@vueform/multiselect'
import { auth } from '@/stores/authorization-state'
import { keywordRepository } from '@/stores/repository/keyword'
import { powerWordAssetRepository } from '@/stores/repository/powerword_asset'
import { campaignAdgroupRepository } from '@/stores/repository/campaign_adgroup'
import {
  MESSAGE_FAILED_TO_FETCH_DATA,
  MESSAGE_PLEASE_SPECIFY_ACCOUNT,
} from '@/constants'
import {defineComponent, computed, ref} from 'vue'

const AccountKeywordComponent = defineComponent({
  components: {
    Multiselect,
  },
  setup(_, context) {
    // Account
    const selectedAccount = computed(() => {
      return powerwordState.selectedAccount
    })

    const accounts = computed(() => {
      return powerwordState.accounts
    })

    // Multiselect
    const selectAccountOptions = computed(() => {
      return powerwordState.accountOptions
    })
    const selectedAccountValue = ref<Account | null>(null)
    const onSelectAccount = (account: Account) => {
      powerwordState.setSelectedAccount(account)
      keywordRepository.fetchKeywords({
        token: auth.token,
        mediaId: account.media_id,
        accountId: account.account_id,
      }).then((ks) => {
        powerwordState.setKeywords(ks)
      })
    }

    // Keyword
    const keywords = computed(() => {
      return powerwordState.keywords
    })

    const onChangeKeyword = (keyword: Keyword) => {
      const client = powerwordState.selectedClient
      if (!client) { return }
      powerwordState.setSelectedKeyword(keyword)
      powerwordState.setLoadingOn()
      context.emit('resetInputs')

      // PW Asset
      const pwap = powerWordAssetRepository.fetchPowerWordAssets({
        token: auth.token,
        clientId: client.client_id,
      }).then((pas) => {
        powerwordState.setPowerWordAssets(pas)
        powerwordState.reloadPowerWordAssetsForView()
      }).catch((e) => {
        return new Promise((resolve, reject) => {
          reject()
        })
      })

      Promise.all([pwap]).then(() => {
        powerwordState.setLoadingOff()
      }).catch((e) => {
        powerwordState.setLoadingOff()
        // エラー表示
        alert(MESSAGE_FAILED_TO_FETCH_DATA)
      })
    }

    const isCheckedKeyword = computed(() => {
      return (keyword: Keyword) => {
        if (powerwordState.selectedKeyword !== null) {
          return powerwordState.selectedKeyword.key === keyword.key
        }
        return false
      }
    })

    // 登録キーワード中に重複するKW文言があればtrue
    const isDuplicatedKeyword = computed(() => {
      return (keyword: Keyword) => {
        let countDuplicated = 0
        powerwordState.keywords.forEach((k) => {
          if (k.keyword === keyword.keyword) {
            countDuplicated++
          }
        })
        return countDuplicated > 1
      }
    })

    const openDeleteKeywordsModal = () => {
      powerwordState.setDeleteKeywordsModal(true)
    }

    const openRegisterKeywordsModal = () => {
      powerwordState.setLoadingOn()
      // キーワード登録モーダルを開くときにadgroupsを初期化
      powerwordState.setSelectedAdGroup(null)
      powerwordState.setAdGroups([])
      const account = powerwordState.selectedAccount
      if (!account) {
        powerwordState.setLoadingOff()
        alert(MESSAGE_PLEASE_SPECIFY_ACCOUNT)
        powerwordState.setRegisterKeywordsModal(false)
        return
      }
      campaignAdgroupRepository.fetchCampaignAdGroups({
        token: auth.token,
        account,
      }).then((v) => {
        powerwordState.setAdGroups(v)
      }).then(() => {
        powerwordState.setLoadingOff()
        powerwordState.setRegisterKeywordsModal(true)
      })
    }

    const openBulkRegisterKeywordsModal = () => {
      powerwordState.setBulkRegisterKeywordsModal(true)
    }

    const openBulkDeleteKeywordsModal = () => {
      powerwordState.setBulkDeleteKeywordsModal(true)
    }

    return {
      selectedAccount,
      accounts,
      selectAccountOptions,
      selectedAccountValue,
      onSelectAccount,
      keywords,
      onChangeKeyword,
      isCheckedKeyword,
      isDuplicatedKeyword,
      openDeleteKeywordsModal,
      openRegisterKeywordsModal,
      openBulkRegisterKeywordsModal,
      openBulkDeleteKeywordsModal,
    }
  },
})

export default AccountKeywordComponent
</script>
