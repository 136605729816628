import {
  ASSET_PINNED_FILED,
  ASSET_TYPE,
  CandidateAsset,
  ChampionRSAAd,
  LLMAsset,
  MEDIA_ID, NG_TYPE,
  OnDemandCombinedAds,
  RSAAsset,
  RSAAssetImp,
} from '@/stores/model/domain'
import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor'

interface ChampionRSAAssetResponse extends DataServiceResponse {
  word: string
  asset_type: number
  pinned_field: ASSET_PINNED_FILED | null
  imp: number
}

interface RSAAdResponse extends DataServiceResponse {
  ss_id: number
  media_id: number
  account_id: string
  campaign_id: string
  adgroup_id: string
  ad_id: string
  ad_type: string | null
  asset_id: string | null
  final_url: string | null
  path_1: string | null
  path_2: string | null
  assets: ChampionRSAAssetResponse[]
}

export interface CandidateAssetResponse {
  generator_asset_id: number | null
  ss_id: number
  media_id: MEDIA_ID | null
  campaign_id: string
  adgroup_id: string
  keyword_id: string | null
  keyword: string | null
  match_type: string | null
  asset_type: ASSET_TYPE
  asset_text: string
  generated_date: string | null
  pinned_field: ASSET_PINNED_FILED | null
}

export interface ChampionRSAAdResponse extends DataServiceResponse {
  rsa_ad: RSAAdResponse
  candidate_assets: CandidateAssetResponse[]
}

interface RSAAdAssetEstimateResponse extends DataServiceResponse {
  rsa_score: number
  ad_strength: number
}

interface RSAAdAssetCombineResponses extends DataServiceResponse {
  rsa_score: number
  ad_strength: number
  candidate_assets: CandidateAssetResponse[]
}

interface RSAAdAssetPostResponse extends DataServiceResponse {}

class RSAAdRepository {
  public async fetchChampionRSAAd(data: {
        token: string,
        ss_id: number,
        media_id: number,
        account_id: string,
        campaign_id: string,
        adgroup_id: string,
        with_candidate_assets: boolean,
      }): Promise<OnDemandCombinedAds> {
    const endpoint = `/rsa_ad/champion`
    return DataService.get<ChampionRSAAdResponse>({
      path: endpoint,
      param: {
        ss_id: data.ss_id,
        media_id: data.media_id,
        account_id: data.account_id,
        campaign_id: data.campaign_id,
        adgroup_id: data.adgroup_id,
        with_candidate_assets: data.with_candidate_assets,
      },
      token: data.token,
    }).then((cra) => {
      // 配信中RSAAd（勝ちRSAAd）
      const assets: RSAAssetImp[] = cra.rsa_ad.assets.map((a) => {
        return {
          word: a.word,
          asset_type: a.asset_type,
          pinned_field: a.pinned_field,
          imp: a.imp,
          generator_asset_id: null,
          keyword_id: null,
          keyword: null,
          asset_text_ng_tagged: a.word,
          ng_check_types: new Set(),
        }
      })
      const champion_ad: ChampionRSAAd = {
        ss_id: cra.rsa_ad.ss_id,
        media_id: cra.rsa_ad.media_id,
        account_id: cra.rsa_ad.account_id,
        campaign_id: cra.rsa_ad.campaign_id,
        adgroup_id: cra.rsa_ad.adgroup_id,
        ad_id: cra.rsa_ad.ad_id,
        ad_type: cra.rsa_ad.ad_type,
        asset_id: cra.rsa_ad.asset_id,
        final_url: cra.rsa_ad.final_url,
        path_1: cra.rsa_ad.path_1,
        path_2: cra.rsa_ad.path_2,
        title_assets: assets.filter((a) => a.asset_type === ASSET_TYPE.TITLE) as RSAAssetImp[],
        description_assets: assets.filter((a) => a.asset_type === ASSET_TYPE.DESCRIPTION) as RSAAssetImp[],
      }
      const candidate_assets: CandidateAsset[] = cra.candidate_assets.map((ca) => {
        return {
          generator_asset_id: ca.generator_asset_id,
          ss_id: ca.ss_id,
          media_id: ca.media_id,
          campaign_id: ca.campaign_id,
          adgroup_id: ca.adgroup_id,
          keyword_id: ca.keyword_id,
          keyword: ca.keyword,
          match_type: ca.match_type,
          asset_type: ca.asset_type,
          asset_text: ca.asset_text,
          generated_date: ca.generated_date,
          asset_text_default: ca.asset_text,
          asset_text_ng_tagged: ca.asset_text,
          is_editable: !!ca.generator_asset_id,
          pinned_field: ca.pinned_field,
          ng_check_types: new Set(),
        }
      }) as CandidateAsset[]

      return {
        champion_ad,
        candidate_assets,
      }
    })
  }

  public async estimateRSAAsset(data: {
    token: string,
    ss_id: number,
    media_id: number,
    account_id: string,
    campaign_id: string,
    adgroup_id: string,
    rsaAssets: RSAAsset[],
  }): Promise<RSAAdAssetEstimateResponse> {
    const endpoint = `/rsa_ad/asset/estimate`
    return DataService.post<RSAAdAssetEstimateResponse>({
      path: endpoint,
      param: {
        ss_id: data.ss_id,
        media_id: data.media_id,
        account_id: data.account_id,
        campaign_id: data.campaign_id,
        adgroup_id: data.adgroup_id,
        assets: data.rsaAssets,
      },
      token: data.token,
    }).then((aer) => {
      return aer
    }).catch((e) => {
      return Promise.reject(e.toString())
    })
  }

  public async combineRSAAsset(data: {
    token: string,
    ss_id: number,
    media_id: number,
    account_id: string,
    campaign_id: string,
    adgroup_id: string,
    rsaAssets: RSAAsset[],
    llmCandidateAssets: CandidateAsset[],
  }): Promise<RSAAdAssetCombineResponses> {
    const endpoint = `/rsa_ad/asset/combine`
    return DataService.post<RSAAdAssetCombineResponses>({
      path: endpoint,
      param: {
        ss_id: data.ss_id,
        media_id: data.media_id,
        account_id: data.account_id,
        campaign_id: data.campaign_id,
        adgroup_id: data.adgroup_id,
        rsa_assets: data.rsaAssets,
        candidate_assets: data.llmCandidateAssets.map((llmca) => {
          return {
            generator_asset_id: null,
            ss_id: llmca.ss_id,
            media_id: llmca.media_id,
            campaign_id: llmca.campaign_id,
            adgroup_id: llmca.adgroup_id,
            keyword_id: llmca.keyword_id,
            keyword: llmca.keyword,
            generated_date: null,
            match_type: llmca.match_type,
            asset_type: llmca.asset_type,
            word: llmca.asset_text,
          }
        }),
      },
      token: data.token,
    }).then((arrs) => {
      return arrs
    }).catch((e) => {
      return Promise.reject(e.toString())
    })
  }

  public async postRSAAsset(data: {
    token: string,
    user: string,
    client_id: number,
    ss_id: number,
    media_id: number,
    account_id: string,
    account_name: string,
    campaign_id: string,
    campaign_name: string,
    adgroup_id: string,
    adgroup_name: string,
    pre_combined_rsa_id: string | null,
    caAssets: CandidateAsset[],
  }): Promise<null> {
    const endpoint = `/rsa_ad/asset`
    return DataService.post<RSAAdAssetPostResponse>({
      path: endpoint,
      param: {
        client_id: data.client_id,
        ss_id: data.ss_id,
        media_id: data.media_id,
        account_id: data.account_id,
        account_name: data.account_name,
        campaign_id: data.campaign_id,
        campaign_name: data.campaign_name,
        adgroup_id: data.adgroup_id,
        adgroup_name: data.adgroup_name,
        created_user: data.user,
        pre_combined_rsa_id: data.pre_combined_rsa_id,
        assets: data.caAssets.map((ca) => {
          return {
            generator_asset_id: ca.generator_asset_id,
            asset_type: ca.asset_type,
            asset_text: ca.asset_text,
            asset_text_default: ca.asset_text_default,
            is_editable: ca.is_editable,
            pinned_field: ca.pinned_field,
          }
        }),
      },
      token: data.token,
    }).then((apr) => {
      return null
    }).catch((e) => {
      return Promise.reject(e.toString())
    })
  }
}

export const rsaAdRepository = new RSAAdRepository()
